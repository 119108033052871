import {computed} from "vue"
import {useStore} from "vuex"

const useClientes = () => {
  const store = useStore()

  const getClientes = async (busqueda) => {
    await store.dispatch("clientes/getClientes", busqueda)
  }

  const setBusqueda = (busqueda) => {
    store.dispatch("clientes/setBusqueda", busqueda)
  }

  const getClienteInfo = async (cliente) => {
    return await store.dispatch("clientes/getClienteInfo", {cliente})
  }

  const setGuardarCliente = async (cliente) => {
    return await store.dispatch("clientes/setGuardarCliente", cliente)
  }

  const setEliminarCliente = async (cliente) => {
    return await store.dispatch("clientes/setEliminarCliente", {cliente})
  }

  const getClientesCatalogos = async () => {
    await store.dispatch("clientes/getClientesCatalogos")
  }

  const busquedaIni = {
    filtro: "",
  }

  const formaClienteIni = {
    id: "",
    nombre: "",
    rfc: "",
    direccion: "",
    correo: "",
    telefono: "",
    plazo_pago: "",
    contactos: [],
  }

  const formaContactoIni = {
    id: "",
    tipo: "",
    nombre: null,
    telefono: null,
    email: null,
  }

  return {
    getClientes,
    setBusqueda,
    getClienteInfo,
    setGuardarCliente,
    setEliminarCliente,
    getClientesCatalogos,
    busquedaIni,
    formaClienteIni,
    formaContactoIni,
    clientes: computed(() => store.getters["clientes/clientes"]),
    loading: computed(() => store.getters["clientes/loading"]),
    busqueda: computed(() => store.getters["clientes/busqueda"]),
    tipos: computed(() => store.getters["clientes/tipos"]),
  }
}

export default useClientes
